import React, { useEffect } from 'react';
import Fade from 'react-reveal/Fade';
import Heading from 'reusecore/src/elements/Heading';

import {
  FormContainer,
  OrderWrapper,
} from '../FormFlow/formFlow.style';

import { OrderCard, CardTitle } from '../../containers/Interior/SellerGallery/sellerGallery.style';

const orderItem = ({order}) => {

  if (!order) {
    return <div>Salve não encontrado</div>
  }

  if (order.media.length === 0) {
    return <div>Esse Salve ainda não foi gravado.</div>
  }

  return (
    <>
      <Heading as="h1" content={`Hey, veja o Salve que ${order.seller.presentationName} mandou:`} />
      <OrderWrapper>
        <OrderCard key={`gallery_key${order.id}`}>
          <CardTitle>
            Para {order.to}
            <span>De: {order.from}</span>
          </CardTitle>
          <video
            poster={order.media[0].thumbnailUrl}
            controls={true}
            preload="metadata"
          >
            <source
              src={order.media[0].publicMediaUrl}
              type="video/mp4"
              className="carousel_img"
            />
          </video>
        </OrderCard>
      </OrderWrapper> 
    </>
  )
}

const Order = ({ data }) => {

  useEffect(() => {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  return (
    <FormContainer>
      <Fade bottom delay={30}>
        {
          orderItem({order: data.order})
        }
      </Fade>
    </FormContainer>
  );
};

export default Order;
