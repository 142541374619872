import React, { Fragment } from 'react';
import { useQuery } from '@apollo/react-hooks';
import queries from '../../../datasource/queries';
import OrderComponent from '../../../components/Order';
import queryString from 'query-string';

import {
  PhotoWrapper,
  BaloonTipRight,
  BaloonTipUp,
  OrderWrapper,
  BannerWrapper,
  PresentationArea,
} from '../../../containers/Interior/SellerBanner/sellerBanner.style';

const Order = props => {


  const { id } = queryString.parse(props.location.search);

  const { loading, error, data } = useQuery(queries.ORDER, {
    variables: {
      input: id,
    }
  });

  if (loading) return (
    <div>Loading..</div>  
  )

  if (error) return (
    <div>Error..</div>  
  )

  if (!data.order) return (
    <div>Pedido não encontrado.</div>  
  )

  console.log('Data result:', data);
    
  return (
    <BannerWrapper>
      <PresentationArea>
        {
          data.order ? (
            <Fragment>
              <PhotoWrapper style={{ backgroundImage: `url(${data.order.seller.imageUrl})` }}>
                <BaloonTipRight />
                <BaloonTipUp />
              </PhotoWrapper>
              <OrderWrapper>
                <OrderComponent data={data} />
              </OrderWrapper>
            </Fragment>
          ) : <div></div>
        }
      </PresentationArea>
    </BannerWrapper>
  );
};

export default Order;