import React, { Fragment } from 'react';
import { Router } from "@reach/router"
import Sticky from 'react-stickynode';
import { ThemeProvider } from 'styled-components';
import { interiorTheme } from 'common/src/theme/interior';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import Navbar from '../containers/Interior/Navbar';
import Feature from '../containers/Interior/Feature';
import Footer from '../containers/Interior/Footer';
import Order from '../containers/Interior/Order'
import { ResetCSS } from 'common/src/assets/css/style';

import {
  GlobalStyle,
  InteriorWrapper,
  ContentWrapper
} from '../containers/Interior/interior.style';

import SEO from '../components/seo';

export default props => {

  return (
    <ThemeProvider theme={interiorTheme}>
      <Fragment>
        <SEO title="Seu salve" />
        <ResetCSS />
        <GlobalStyle />

        {/* Start writing your markup from here. */}
        <InteriorWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active" className="sticky-internal">
            <DrawerProvider>
              <Navbar />
            </DrawerProvider>
          </Sticky>
          <ContentWrapper>
            <Router>
              <Order path="/salve/*" />
            </Router>
            <Feature />
          </ContentWrapper>
          <Footer />
        </InteriorWrapper>
        {/* End of markup section. */}
      </Fragment>
    </ThemeProvider>
  );
};
